import React, { Component } from 'react';
import { axiosPost } from '../../../utils/AxiosApi';
import {
  build,
  URL,
  loginPathName,
  createTermsAndConditionPermissionCode,
  companySetting,
  companyBasicSetting,
  IMPEXO_WEBSITE,
  baAdminPermission
} from '../../../utils/Constants';
import history from '../../../utils/History';
import { withRouter } from 'react-router';
import '../../../assets/scss/menu.scss';
import '../../../assets/scss/app.scss';
import '../../../assets/scss/spinner.scss';
import logoWhite from '../../../assets/images/new4small.png';
import tagLine from '../../../assets/images/tagline-2.png';
import bgImage from '../../../assets/images/login-bg.png';
import bgImageShape from '../../../assets/images/login_bg_shape.png';
import swal from 'sweetalert';
import { permissionCheckActivity } from '../../../utils/PermissionChecker';
import LoadingAnimation from '../../LoadingAnimation/LoadingAnimation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faForward } from '@fortawesome/free-solid-svg-icons';
import './SignIn.scss';
import logoArrow from '../../../assets/images/logoArrow.png';

/**
 * @author Saurav Sitaula
 * @since 1.0
 */
class Signin extends Component {
  state = {
    username: '',
    password: '',
    processing: false,
    showPassword: false
  };

  componentDidMount() {
    if (this.props?.location && this.props.location?.pathname === loginPathName) {
      localStorage.clear();
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handlelogin = (e) => {
    // e.preventDefault();
    if (this.state.username !== '' && this.state.password !== '') {
      let data = {
        username: this.state.username,
        password: this.state.password
      };
      this.setState({ processing: true });
      axiosPost(
        URL.loginUrl,
        data,
        (response) => {
          if (response.data.success) {
            this.props.successSetter && this.props.successSetter();

            if (this.state.username === 'techart-admin') {
              localStorage.setItem('token', response.data.data.token);
              localStorage.setItem(
                'loggedInUserId',
                JSON.stringify(response.data.data.loggedInUserId)
              );

              this.props.history.push(`/super-user-dashboard`);
            } else {
              localStorage.setItem('isSystemVerified', response.data.data.isSystemVerified);
              localStorage.setItem('isEmailVerified', response.data.data.isEmailVerified);
              localStorage.setItem(
                'hasCompleteCompanySetting',
                response.data.data.hasCompleteCompanySetting
              );

              localStorage.setItem(
                'hasAcceptedSystemTAndC',
                response.data.data.hasAcceptedSystemTAndC
              );

              localStorage.setItem(
                'hasAcceptedActiveTAndC',
                response.data.data.hasAcceptedActiveTAndC
              );

              localStorage.setItem(
                'modulesPermissions',
                JSON.stringify(response.data.data.permissions)
              );
              localStorage.setItem('customerKyc', JSON.stringify(response.data.data.customerKyc));
              localStorage.setItem('roles', JSON.stringify(response.data.data.roles));
              localStorage.setItem('token', response.data.data.token);

              localStorage.setItem(
                'loggedInUserId',
                JSON.stringify(response.data.data.loggedInUserId)
              );
              localStorage.setItem('isFirstLogin', JSON.stringify(response.data.data.isFirstLogin));

              let termsAndCondition = response.data.data.hasTermAndCondition,
                sysVerified = response.data.data.isSystemVerified,
                emaiilVerified = response.data.data.isEmailVerified,
                systandcVerified = response.data.data.hasAcceptedSystemTAndC,
                categoryCode = response.data.data.customerKyc?.companyCategory.categoryCode,
                hasCompleteCompanySetting = response.data.data.hasCompleteCompanySetting,
                isFirstLogin = response.data.data.isFirstLogin;
              const permissions = response.data.data.permissions;
              const hasCreateTermsAndConditionPermission = permissions.some(function (permission) {
                return permission.name === createTermsAndConditionPermissionCode;
              });
              if (sysVerified === 1 && emaiilVerified === 1) {
                if (categoryCode !== 'CAT_CUS') {
                  if (termsAndCondition) {
                    this.companySettingRedirect(
                      hasCompleteCompanySetting,
                      permissions,
                      isFirstLogin
                    );
                  } else {
                    if (hasCreateTermsAndConditionPermission) {
                      swal({
                        title: 'Note',
                        text: 'No Terms and Condition has been created. Do you want to create it first ?',
                        icon: 'info',
                        dangerMode: false,
                        closeOnClickOutside: false,
                        outsideClick: false,
                        buttons: {
                          cancel: true,
                          confirm: true
                        },
                        buttons: ['Skip', 'Ok']
                      }).then((willDelete) => {
                        if (willDelete) {
                          this.props.history.push(`/dashboard/termsAndConditions`);
                        } else {
                          if (isFirstLogin) {
                            this.redirectToUpdatePassword();
                          } else {
                            this.companySettingRedirect(
                              hasCompleteCompanySetting,
                              permissions,
                              isFirstLogin
                            );
                          }
                        }
                      });
                    } else {
                      if (isFirstLogin) {
                        this.redirectToUpdatePassword();
                      } else {
                        this.props.history.push(`/dashboard`);
                      }
                    }
                  }
                } else {
                  if (isFirstLogin) {
                    this.redirectToUpdatePassword();
                  } else {
                    this.props.history.push(`/dashboard`);
                  }
                }
              } else if (sysVerified !== 1 || emaiilVerified !== 1) {
                this.props.history.push(`/tandc`);
              } else {
                if (isFirstLogin) {
                  this.redirectToUpdatePassword();
                } else {
                  this.props.history.push(`/dashboard`);
                }
              }
            }
          }
        },
        (error) => {
          this.setState({
            processing: false
          });
          let errorResponse = error.response ? error.response.data : error;
          swal(errorResponse.error || 'Error', errorResponse.message, 'error');
        }
      );
    }
  };

  companySettingRedirect = (hasCompleteCompanySetting, permissions, isFirstLogin) => {
    if (isFirstLogin) {
      this.redirectToUpdatePassword();
    } else {
      if (!hasCompleteCompanySetting) {
        const companySettingPermission = permissions.some((permission) => {
          return permission.name === companySetting;
        });
        const companyBasicSettingPermission = permissions.some((permission) => {
          return permission.name === companyBasicSetting;
        });
        if (companySettingPermission && companyBasicSettingPermission) {
          permissionCheckActivity([baAdminPermission])
            ? this.props.history.push(`/`)
            : this.props.history.push(`/dashboard/companySettings`);
        } else {
          this.props.history.push(`/dashboard`);
        }
      } else {
        this.props.history.push(`/dashboard`);
      }
    }
  };

  redirectToUpdatePassword = () => {
    this.props.history.push({
      pathname: `/change-password`
    });
  };

  redirectToWebiste = () => {
    window.open(IMPEXO_WEBSITE);
  };

  handleTogglePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword
    }));
  };

  render() {
    return (
      <>
        {this.state.processing ? (
          <LoadingAnimation />
        ) : (
          // <div className="landingLogin">
          //   <div className="login_bg_img_div">
          //     <img className="bg_img_shape"  alt="img" />
          //     <img className="bg_img" src={build + bgImage} alt="img" />
          //   </div>
          //   <div className="logisticText">
          //     <p>
          //       <strong>
          //         <span style={{fontSize:"0.8rem",fontWeight:"200",color:"#dee6f0"}}>FAST FORWARD SOLUTION</span>
          //         <br />
          //         <span style={{fontSize:"2rem",fontWeight:"400"}}>FOR ALL</span>
          //         <br />
          //         <span style={{ display:'flex',alignItems:'center',fontSize:"3rem",fontWeight:"550",lineHeight:"0.8",whiteSpace:"nowrap",marginLeft:"0.5rem"}}>
          //           {/* <FontAwesomeIcon icon={faForward} className="uploadBtn mr-2" /> */}
          //           <img className="bg_img" src={logoArrow} style={{width:"30px"}} alt="img" />
          //           LOGISTICS BUSINESS
          //         </span>
          //       </strong>
          //     </p>
          //     {/* <img src={build + tagLine} className="tagline" alt="img" /> */}
          //   </div>
          //   <div className="login-form">
          //     <form>
          //       <a href={IMPEXO_WEBSITE} target="_blank" rel="noreferrer">
          //         <img src={build + logoWhite} className="impexo-white" alt="img" />
          //       </a>
          //       <div className="form-group ">
          //         <input
          //           type="text"
          //           className="ex-work-texts login-textfield"
          //           placeholder="Username"
          //           name="username"
          //           required="required"
          //           onChange={this.handleChange}
          //         />
          //       </div>
          //       <div className="form-group password-input-wrapper">
          //         <input
          //           type={this.state.showPassword ? 'text' : 'password'}
          //           className="ex-work-texts login-textfield"
          //           placeholder="Password"
          //           name="password"
          //           required="required"
          //           onChange={this.handleChange}
          //         />
          //         <span className="password-toggle" onClick={this.handleTogglePassword}>
          //           <FontAwesomeIcon
          //             icon={this.state.showPassword ? faEyeSlash : faEye}
          //             size="lg"
          //           />
          //         </span>
          //       </div>
          //       <div className="form-group customLoginBtnCenter">
          //         <button
          //           type="submit"
          //           id="loginbutton"
          //           className="customButton"
          //           onClick={this.handlelogin}
          //         >
          //           Login
          //         </button>
          //       </div>
          //       <div className="clearfix">
          //         <a href="/forgotPassword" className="pull-right forgotpsw">
          //           Forgot Password?
          //         </a>
          //       </div>
          //     </form>
          //   </div>
          // </div>

          <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
            <img
              src={build + bgImage}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                zIndex: '-1'
              }}
              alt="img"
            />

            <div className="wrapperLogin">
              <div className="hexaBlue">
                <div className="arrowHexa"></div>

                <form className="login-formV2">
                  <a href={IMPEXO_WEBSITE} target="_blank" rel="noreferrer">
                    <img src={build + logoWhite} className="impexo-white" alt="img" />
                  </a>
                  <div className="form-group ">
                    <input
                      type="text"
                      className="ex-work-texts login-textfield"
                      placeholder="Username"
                      name="username"
                      required="required"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group password-input-wrapper">
                    <input
                      type={this.state.showPassword ? 'text' : 'password'}
                      className="ex-work-texts login-textfield"
                      placeholder="Password"
                      name="password"
                      required="required"
                      onChange={this.handleChange}
                    />
                    <span className="password-toggle" onClick={this.handleTogglePassword}>
                      <FontAwesomeIcon
                        icon={this.state.showPassword ? faEyeSlash : faEye}
                        size="lg"
                      />
                    </span>
                  </div>
                  <div className="form-group customLoginBtnCenter">
                    <button
                      type="submit"
                      id="loginbutton"
                      className="customButton"
                      onClick={this.handlelogin}
                    >
                      Login
                    </button>
                  </div>
                  <div className="clearfix">
                    <a href="/forgotPassword" className="pull-right forgotpsw">
                      Forgot Password?
                    </a>
                  </div>
                </form>
              </div>
              <div className="LoginTitleText">
                <p>
                  <strong>
                    <span style={{ fontSize: '0.8rem', fontWeight: '200', color: '#dee6f0' }}>
                      FAST FORWARD SOLUTION
                    </span>
                    <br />
                    <span className="loginForAllText">FOR ALL</span>
                    <br />
                    <span className="imageLogisticBusiness">
                      {/* <FontAwesomeIcon icon={faForward} className="uploadBtn mr-2" /> */}
                      <img
                        className="bg_img"
                        src={logoArrow}
                        style={{ width: '30px', color: '#fff' }}
                        alt="img"
                      />
                      LOGISTICS BUSINESS
                    </span>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(Signin);
