//actions
const LOAD_NOTIFICATIONS = 'NOTIFICATIONS/LOAD';
const LOAD_NOTIFICATIONCOUNT = 'NotificationCount/LOAD';
const LOAD_NOTIFICATIONTOTALCOUNT = 'NotificationTotalCount/LOAD';
const LOAD_BADGE_NOTIFICATION = 'BADGE_NOTIFICATION/LOAD';
const RESET_NOTIFICATION = 'RESET_NOTIFICATION';

//actions-creator
export function loadNotifications(notifications) {
  return { type: LOAD_NOTIFICATIONS, payload: notifications };
}

export function loadNotificationCount(count) {
  return { type: LOAD_NOTIFICATIONCOUNT, payload: count };
}

export function loadNotificationTotalCount(count) {
  return { type: LOAD_NOTIFICATIONTOTALCOUNT, payload: count };
}

export function loadBadgeNotification(notifications) {
  return { type: LOAD_BADGE_NOTIFICATION, payload: notifications };
}
//reset navHeader Ducks
export function resetNofificationDucks() {
  return { type: RESET_NOTIFICATION, payload: null };
}

//reducer
export default function reducer(
  state = {
    notifications: null,
    badgeNotifications: null,
    unreadNotificationCount: 0,
    totalCount: 0
  },
  action
) {
  switch (action.type) {
    case LOAD_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case LOAD_NOTIFICATIONCOUNT:
      return { ...state, unreadNotificationCount: action.payload };
    case LOAD_NOTIFICATIONTOTALCOUNT:
      return { ...state, totalCount: action.payload };
    case LOAD_BADGE_NOTIFICATION:
      return { ...state, badgeNotifications: action.payload };
    case RESET_NOTIFICATION:
      return {
        ...state,
        notifications: null,
        badgeNotifications: null,
        unreadNotificationCount: 0,
        totalCount: 0
      };
    default:
      return state;
  }
}

export function storeNotifications(notifications) {
  return (dispatch) => {
    dispatch(loadNotifications(notifications));
  };
}

export function storeNotificationCount(count) {
  return (dispatch) => {
    dispatch(loadNotificationCount(count));
  };
}

export function storeNotificationTotalCount(count) {
  return (dispatch) => {
    dispatch(loadNotificationTotalCount(count));
  };
}

export function storeBadgeNotifications(notifications) {
  return (dispatch) => {
    dispatch(loadBadgeNotification(notifications));
  };
}

export function resetNotificationReduxState() {
  return (dispatch) => {
    dispatch(resetNofificationDucks());
  };
}
