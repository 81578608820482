import { axiosGet } from '../utils/AxiosApi';
import { URL } from '../utils/Constants';
import { checkPermissionForModules } from '../utils/PermissionChecker';

//todo navheader redux store

//actions
const modulesLOAD = 'modules/LOAD';
// const clearState = "modules/CLEAR";
const navProfileLOAD = 'navProfile/LOAD';
// const navProfileclear = "navProfile/CLEAR";

const RESET_NAV = 'RESET_NAV';
//actions-creator
export function loadModules(modules) {
  return { type: modulesLOAD, payload: modules };
}

//actions-creator
export function loadNavProfile(profile) {
  return { type: navProfileLOAD, payload: profile };
}

//reset navHeader Ducks
export function resetNavDucks() {
  return { type: RESET_NAV, payload: null };
}

//reducer
export default function reducer(
  state = {
    modules: null,
    navProfile: null
  },
  action
) {
  switch (action.type) {
    case modulesLOAD:
      return { ...state, modules: action.payload };
    case navProfileLOAD:
      return { ...state, navProfile: action.payload };
    case RESET_NAV:
      return { ...state, modules: null, navProfile: null };
    default:
      return state;
  }
}

export const getModules = () => (dispatch) => {
  axiosGet(
    URL.selectModules,
    (response) => {
      if (response.data.success) {
        const modules = response.data.data.items.map((newItem) => ({
          ...newItem,
          isOpen: false
        }));
        const filteredParentModules = filterModulesByPermission(modules);
        dispatch(loadModules(filteredParentModules));
      }
    },
    (err) => {
      console.log(err);
    }
  );
};

function filterModulesByPermission(modules) {
  return modules.filter((module) => {
    // check if parent module or submodule under it is in the permission list otherwise remove
    if (
      checkPermissionForModules(module.name) ||
      (module.nodes && module.nodes.some((node) => checkPermissionForModules(node.name)))
    ) {
      module.nodes = module.nodes.filter((node) => checkPermissionForModules(node.name));
      return true;
    } else {
      return false;
    }
  });
}

export function setSubModuleToggle(modules) {
  return (dispatch) => {
    dispatch(loadModules(modules));
  };
}

export function clearNavProfile(profile) {
  return (dispatch) => {
    dispatch(loadNavProfile(profile));
  };
}

export const getNavMyProfile = () => (dispatch) => {
  axiosGet(URL.getMyProfile, (res) => {
    if (res.data.success) {
      dispatch(loadNavProfile(res.data.data.item));
    }
  });
};

export function resetNavReduxState() {
  return (dispatch) => {
    dispatch(resetNavDucks());
  };
}
