import React from 'react';

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="flex text-center">
      <p>Something went wrong: There was an error in this page.</p>
      <pre>{error.message}</pre>
      <p>Contacts the devs at support@techartttrekkies.com</p>
    </div>
  );
}

export default ErrorFallback;
