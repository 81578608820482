//actions
const ADD_CONTAINER_DETAIL = 'ADD_CONTAINER_DETAIL';
const RESET_CONTAINER_DETAIL = 'RESET_CONTAINER_DETAIL';

export function addContainerTrack(data) {
  return { type: ADD_CONTAINER_DETAIL, payload: data };
}

export function resetContainerTrack() {
  return { type: RESET_CONTAINER_DETAIL, payload: [] };
}

//reducer
export default function reducer(
  state = {
    containerTrackData: []
  },
  action
) {
  switch (action.type) {
    case ADD_CONTAINER_DETAIL:
      let currentStateData = [...state.containerTrackData];
      function idExists(id) {
        return currentStateData.some((item) => item.id === id);
      }
      if (idExists(action?.payload?.id)) {
        currentStateData = currentStateData.map((item) => {
          if (item.id == action.payload.id) {
            return {
              ...item,
              containerDetails: action.payload.containerDetails
            };
          }
          return item;
        });
      } else {
        currentStateData.push(action.payload);
      }

      return {
        ...state,
        containerTrackData: currentStateData
      };

    case RESET_CONTAINER_DETAIL:
      return { ...state, containerTrackData: [] };

    default:
      return state;
  }
}

export function addContainerTrackIndividual(data) {
  return (dispatch) => {
    dispatch(addContainerTrack(data));
  };
}

export function resetContainerTrackIndividual() {
  return (dispatch) => {
    dispatch(resetContainerTrack());
  };
}
