import React, { Component } from 'react';
import { Button } from 'reactstrap';
class PrimaryButton extends Component {
  state = {};
  render() {
    return (
      <>
        <Button
          className={`button-primary ${this.props?.className}`}
          onClick={this.props.handleClick}
        >
          {this.props.buttonTitle}
        </Button>
      </>
    );
  }
}

export default PrimaryButton;
