import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-mdl/extra/material.css';
import 'react-mdl/extra/material.js';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import './App.css';
import * as serviceWorker from './serviceWorker';
import history from './utils/History';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';

//BrowserRouter: This is a router implementation from react-router-dom that uses the HTML5 history API. It's given a custom history object and a base URL.

//Provider: This is a Redux provider that makes the Redux store available to all components within the app.

//PersistGate: This is a component from redux-persist that delays the rendering of the app's UI until the persisted state has been retrieved and saved to the Redux store.

ReactDOM.render(
  <Router history={history} basename={'/'}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>,
  document.getElementById('root')
);

serviceWorker.unregister();
